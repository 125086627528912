/* eslint-disable @typescript-eslint/no-unused-vars, jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */

import "./Navbar.css"
import 'rodal/lib/rodal.css'
import { useContext, useState } from "react"
import {
    HashLink,
    Link,
    DownOutlined,
    MenuDropdown,
    Dropdown,
    Menu,
    Rodal,
    useMediaQuery,
    Cookies,
    Redirect
} from "../../Constants/libraries"
import { 
    team_msia,
    team_usa
} from "../../Constants/texts"
import { Context_Username } from "../../Constants/contexts"

type Props = {
    className?: string;
    contrast? : boolean;
    animation? : boolean;
}

export const Navbar: React.FC<Props> = (props) => {

    const [hamburger, setHamburger] = useState(false)
    const [modalLeftOri, setModalLeftOri] = useState(false)
    const [modalRightOri, setModalRightOri] = useState(false)
    const [redirect, setRedirect] = useState("")
    const tablet = useMediaQuery({ query: "(max-width: 770px)"})

    const menu_dropdown = (
        <MenuDropdown>
            <MenuDropdown.Item>
                <p onClick={() => {setModalLeftOri(true)}}>
                Aerotrends Aviation Sdn Bhd
                </p>
            </MenuDropdown.Item>
            <MenuDropdown.Item>
                <p onClick={() => {setModalRightOri(true)}}>
                    Aerotrends Aviation LLC
                </p>
            </MenuDropdown.Item>
        </MenuDropdown>
    )

    const login_dropdown = (
        <MenuDropdown>
            <MenuDropdown.Item>
                <p onClick={() => setRedirect("dashboard")}>
                    Dashboard
                </p>
            </MenuDropdown.Item>
            <MenuDropdown.Item>
                <p onClick={() => setRedirect("quote")}>
                    Quotations
                </p>
            </MenuDropdown.Item>
            <MenuDropdown.Item>
                <p onClick={() => setRedirect("parser")}>
                    Parser
                </p>
            </MenuDropdown.Item>
            <MenuDropdown.Item>
                <p onClick={() => {
                    const cookies = new Cookies()
                    cookies.remove("session_username", { path: "/"})
                    cookies.remove("session_token", { path: "/"})
                    cookies.remove("session_shortname", { path: "/"})
                    setRedirect("")
                    window.location.reload()
                }}>
                    Logout
                </p>
            </MenuDropdown.Item>
        </MenuDropdown>
    )

    const [username, setUsername] = useContext(Context_Username)

    return (
        <>
            {redirect ? <Redirect to={`/${redirect}`}/> : <></>}

            {/* Mobile */}
            {tablet ?   
                <div className="Navbar-Mobile">
                    <Menu 
                        noOverlay 
                        right
                        width={"100%"}
                        isOpen={hamburger}
                        onOpen={() => setHamburger(true)}
                        onClose={() => {setHamburger(false)}}
                    >
                        <Link to="/">
                            <img src="/Images/logo.png" alt="Aerotrends Aviation Logo" height="40" width="150"/>
                        </Link>
                        <div className="Navbar-Mobile__Links">
                            <div>
                                <p>Our Team</p>
                                <p 
                                    onClick={
                                        () => {
                                            setModalLeftOri(true)
                                            setHamburger(false)
                                        }
                                    } 
                                    className="sub_sub"
                                >
                                    Aerotrends Aviation (M) Sdn Bhd
                                </p>
                                <p 
                                    onClick={
                                        () => {
                                            setModalRightOri(true)
                                            setHamburger(false)
                                        } 
                                    }
                                    className="sub_sub"
                                >
                                    Aerotrends Aviation LLC (AALLC)
                                </p>
                            </div>
                            <div>
                                <HashLink to="/about#top" onClick={() => {setHamburger(false)}}>About</HashLink>
                                <HashLink to="/services#top" onClick={() => {setHamburger(false)}}>Services</HashLink>
                                <HashLink to="/contact#top" onClick={() => {setHamburger(false)}}>Contact</HashLink>
                            </div>
                            <div>
                                <hr/>
                                {username ? 
                                    <div>
                                        <p>{username}</p>
                                        <p className="sub_sub" onClick={() => setRedirect("dashboard")}>Dashboard</p>
                                        <p className="sub_sub" onClick={() => setRedirect("quote")}>Quotations</p>
                                        <p className="sub_sub" onClick={() => setRedirect("parser")}>Parser</p>
                                        <p 
                                            className="sub_sub"
                                            onClick={() => {
                                                const cookies = new Cookies()
                                                cookies.remove("session_username", { path: "/"})
                                                cookies.remove("session_token", { path: "/"})
                                                cookies.remove("session_shortname", { path: "/"})
                                                setRedirect("")
                                                window.location.reload()
                                            }}
                                        >Logout</p>
                                    </div>
                                :
                                    <HashLink className="remove" to="/login" onClick={() => setHamburger(false)}>Employee Login</HashLink>
                                }
                            </div>
                        </div>
                    </Menu>
                </div>
            :
                // Desktop
                <div className={props.animation ? "Navbar Navbar__animation" : "Navbar"}>
                    <HashLink to="/">
                        <img src="/Images/logo.png" alt="Aerotrends Aviation Logo" height="40" width="150"/>
                    </HashLink>
                    <div className={ !props.contrast ? "Navbar__links" : "Navbar__links-contrast"}>
                        <Dropdown overlay={menu_dropdown}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>Our Team<DownOutlined className="downArrow"/></a>
                        </Dropdown>
                        <HashLink to="/about#top">About</HashLink>
                        <HashLink to="/services#top">Services</HashLink>
                        <HashLink to="/contact#top">Contact</HashLink>
                        {username ?
                            <Dropdown overlay={login_dropdown}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>{username}<DownOutlined className="downArrow"/></a>
                            </Dropdown>
                        :
                            <HashLink className="remove" to="/login">Employee Login</HashLink>    
                        }
                    </div>
                </div>
            }

            {/* Rodal Msia*/}
            <Rodal 
                visible={modalLeftOri} 
                onClose={() => {
                    setModalLeftOri(false)
                    setModalRightOri(false)
                }}
                enterAnimation="slideLeft"
                leaveAnimation="slideLeft"
                duration={825}
                closeOnEsc
                className="rodal-left"
                showMask={false}
            >
                <div className="Team">
                    <p>Aerotrends Aviation Sdn Bhd</p>
                    {team_msia.map((item:any) => (
                        <div className="displayFlex"> 
                            {Object.entries(item).map((x:any) => (
                                x[1].map((y:any) => (
                                    <p>{y}<p className="sub">{x[0]}</p></p>
                                ))
                            ))}
                        </div>
                    ))}
                </div>
            </Rodal>
            
            {/* Rodal Usa */}
            <Rodal 
                visible={modalRightOri} 
                onClose={() => {
                    setModalRightOri(false)
                    setModalLeftOri(false)
                }}
                enterAnimation="slideLeft"
                leaveAnimation="slideLeft"
                duration={825}
                closeOnEsc
                className="rodal-left"
                showMask={false}
            >
                <div className="Team">
                    <p>Aerotrends Aviation LLC</p>
                    {team_usa.map((item:any) => (
                        <div className="displayFlex"> 
                            {Object.entries(item).map((x:any) => (
                                x[1].map((y:any) => (
                                    <p>{y}<p className="sub">{x[0]}</p></p>
                                ))
                            ))}
                        </div>
                    ))}
                </div>
            </Rodal>
        </>
    )
}

